import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import ModelUserApi from '../../../../../i-public-service/aba-finance/models/user/ModelUserApi';
import PersonalIdentificationData from '../../../../../i-public-service/aba-finance/models/user/identification/PersonalIdentificationData';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import ModelLegalPerson from '../../../../../i-public-service/aba-finance/models/user/ModelLegalPerson';
import ModelNaturalPerson from '../../../../../i-public-service/aba-finance/models/user/ModelNaturalPerson';
import ModelSystemMessages from '../../../../../i-public-service/aba-finance/models/ModelSystemMessages';
import GetRefillRequisitesResponse from '../../../../../aba/cabinet/web/models/GetRefillRequisitesResponse';
import GetPhotosResponse from '../../../../../aba/cabinet/web/models/GetPhotosResponse';
import ModelPersonIdentification from '../../../../../i-public-service/aba-finance/models/user/ModelPersonIdentification';

export default class ProfileWsController {
	webApiService: WebApiService;
	GetLegalPersonEvent = new Events<WsResponseModelT<ModelLegalPerson>>();
	GetNaturalPersonEvent = new Events<WsResponseModelT<ModelNaturalPerson>>();
	GetSystemMessagesEvent = new Events<WsResponseModelT<Array<ModelSystemMessages>>>();
	GetRefillRequisitesEvent = new Events<WsResponseModelT<GetRefillRequisitesResponse>>();
	GetPhotosEvent = new Events<WsResponseModelT<GetPhotosResponse>>();
	CheckIdentifiedEvent = new Events<WsResponseModelT<Boolean>>();
	OrderCardEvent = new Events<WsResponseModelT<Boolean>>();
	SavePersonalIdentificationDataEvent = new Events<WsResponseModelT<Boolean>>();
	GetUserIdentificationEvent = new Events<WsResponseModelT<ModelPersonIdentification>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('ProfileWsController', 'GetLegalPerson', (res: WsResponseModelT<ModelLegalPerson>) => {
			self.GetLegalPersonEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetNaturalPerson', (res: WsResponseModelT<ModelNaturalPerson>) => {
			self.GetNaturalPersonEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetSystemMessages', (res: WsResponseModelT<Array<ModelSystemMessages>>) => {
			self.GetSystemMessagesEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetRefillRequisites', (res: WsResponseModelT<GetRefillRequisitesResponse>) => {
			self.GetRefillRequisitesEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetPhotos', (res: WsResponseModelT<GetPhotosResponse>) => {
			self.GetPhotosEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'CheckIdentified', (res: WsResponseModelT<Boolean>) => {
			self.CheckIdentifiedEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'OrderCard', (res: WsResponseModelT<Boolean>) => {
			self.OrderCardEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'SavePersonalIdentificationData', (res: WsResponseModelT<Boolean>) => {
			self.SavePersonalIdentificationDataEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetUserIdentification', (res: WsResponseModelT<ModelPersonIdentification>) => {
			self.GetUserIdentificationEvent.trigger(res);
		});
	}
	GetLegalPerson() { 	
        this.webApiService.send('ProfileWsController', 'GetLegalPerson' );
    }
	GetNaturalPerson() { 	
        this.webApiService.send('ProfileWsController', 'GetNaturalPerson' );
    }
	GetSystemMessages() { 	
        this.webApiService.send('ProfileWsController', 'GetSystemMessages' );
    }
	GetRefillRequisites() { 	
        this.webApiService.send('ProfileWsController', 'GetRefillRequisites' );
    }
	GetPhotos() { 	
        this.webApiService.send('ProfileWsController', 'GetPhotos' );
    }
	CheckIdentified() { 	
        this.webApiService.send('ProfileWsController', 'CheckIdentified' );
    }
	OrderCard(model: ModelUserApi) { 	
        this.webApiService.send('ProfileWsController', 'OrderCard' , model);
    }
	SavePersonalIdentificationData(model: PersonalIdentificationData) { 	
        this.webApiService.send('ProfileWsController', 'SavePersonalIdentificationData' , model);
    }
	GetUserIdentification() { 	
        this.webApiService.send('ProfileWsController', 'GetUserIdentification' );
    }
	 // async methods
	async getLegalPersonAsync(): Promise<ModelLegalPerson> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLegalPerson.bind(this), this.GetLegalPersonEvent)();
	}
	async getNaturalPersonAsync(): Promise<ModelNaturalPerson> {
		return EventToAsyncDecorator.getInstance().bind(this.GetNaturalPerson.bind(this), this.GetNaturalPersonEvent)();
	}
	async getSystemMessagesAsync(): Promise<Array<ModelSystemMessages>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetSystemMessages.bind(this), this.GetSystemMessagesEvent)();
	}
	async getRefillRequisitesAsync(): Promise<GetRefillRequisitesResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetRefillRequisites.bind(this), this.GetRefillRequisitesEvent)();
	}
	async getPhotosAsync(): Promise<GetPhotosResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetPhotos.bind(this), this.GetPhotosEvent)();
	}
	async checkIdentifiedAsync(): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckIdentified.bind(this), this.CheckIdentifiedEvent)();
	}
	async orderCardAsync(model: ModelUserApi): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.OrderCard.bind(this), this.OrderCardEvent)(model);
	}
	async savePersonalIdentificationDataAsync(model: PersonalIdentificationData): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.SavePersonalIdentificationData.bind(this), this.SavePersonalIdentificationDataEvent)(model);
	}
	async getUserIdentificationAsync(): Promise<ModelPersonIdentification> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUserIdentification.bind(this), this.GetUserIdentificationEvent)();
	}
}
