import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import LegalDirectorsSaveDataRequest from '../../../../../identification-services/models/legal-directors/LegalDirectorsSaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import LegalDirectorsGetDataResponse from '../../../../../identification-services/models/legal-directors/LegalDirectorsGetDataResponse';

export default class LegalDirectorsWsController {
	webApiService: WebApiService;
	GetLegalDirectorsEvent = new Events<WsResponseModelT<LegalDirectorsGetDataResponse>>();
	SaveLegalDirectorsDataEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LegalDirectorsWsController', 'GetLegalDirectors', (res: WsResponseModelT<LegalDirectorsGetDataResponse>) => {
			self.GetLegalDirectorsEvent.trigger(res);
		});
		webApiService.on('LegalDirectorsWsController', 'SaveLegalDirectorsData', (res: WsResponseModelT<Boolean>) => {
			self.SaveLegalDirectorsDataEvent.trigger(res);
		});
	}
	GetLegalDirectors() { 	
        this.webApiService.send('LegalDirectorsWsController', 'GetLegalDirectors' );
    }
	SaveLegalDirectorsData(request: LegalDirectorsSaveDataRequest) { 	
        this.webApiService.send('LegalDirectorsWsController', 'SaveLegalDirectorsData' , request);
    }
	 // async methods
	async getLegalDirectorsAsync(): Promise<LegalDirectorsGetDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLegalDirectors.bind(this), this.GetLegalDirectorsEvent)();
	}
	async saveLegalDirectorsDataAsync(request: LegalDirectorsSaveDataRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.SaveLegalDirectorsData.bind(this), this.SaveLegalDirectorsDataEvent)(request);
	}
}
