import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import ModelPersonTemplate from '../../../../../i-public-service/aba-finance/models/ModelPersonTemplate';

export default class PaymentsWsController {
	webApiService: WebApiService;
	GetTemplatesEvent = new Events<WsResponseModelT<Array<ModelPersonTemplate>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('PaymentsWsController', 'GetTemplates', (res: WsResponseModelT<Array<ModelPersonTemplate>>) => {
			self.GetTemplatesEvent.trigger(res);
		});
	}
	GetTemplates() { 	
        this.webApiService.send('PaymentsWsController', 'GetTemplates' );
    }
	 // async methods
	async getTemplatesAsync(): Promise<Array<ModelPersonTemplate>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTemplates.bind(this), this.GetTemplatesEvent)();
	}
}
