import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import GetCommissionRequest from '../../../../../aba/cabinet/web/models/GetCommissionRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';

export default class CommissionsWsController {
	webApiService: WebApiService;
	GetCommissionEvent = new Events<WsResponseModelT<number>>();
	GetCommissionForSepaPaymentEvent = new Events<WsResponseModelT<number>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('CommissionsWsController', 'GetCommission', (res: WsResponseModelT<number>) => {
			self.GetCommissionEvent.trigger(res);
		});
		webApiService.on('CommissionsWsController', 'GetCommissionForSepaPayment', (res: WsResponseModelT<number>) => {
			self.GetCommissionForSepaPaymentEvent.trigger(res);
		});
	}
	GetCommission(request: GetCommissionRequest) { 	
        this.webApiService.send('CommissionsWsController', 'GetCommission' , request);
    }
	GetCommissionForSepaPayment(amount: number) { 	
        this.webApiService.send('CommissionsWsController', 'GetCommissionForSepaPayment' , amount);
    }
	 // async methods
	async getCommissionAsync(request: GetCommissionRequest): Promise<number> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCommission.bind(this), this.GetCommissionEvent)(request);
	}
	async getCommissionForSepaPaymentAsync(amount: number): Promise<number> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCommissionForSepaPayment.bind(this), this.GetCommissionForSepaPaymentEvent)(amount);
	}
}
