<template>
<DocumentLayout>
	<h1 class="h1--hero">
		<div class="hero-h-wrapper">
			<div data-w-id="a57a7ba6-1456-df65-733e-c340ef917366" class="h--super-large">
				COMPLAINTS
			</div>
		</div>
		<div class="hero-h-wrapper">
			<div data-w-id="201b457d-0993-bca7-76e5-88cdccd4a159" class="h--super-large">
				DISCLAIMER
			</div>
		</div>
	</h1>
	<h2><a name="__DdeLink__121_1676823099"></a> <strong>How We Handle Your Complaints</strong></h2>
	<p align="JUSTIFY">It&rsquo;s of our great importance that you carefully read information provided below and fully understand how we handle your complaints!</p>
	<p align="JUSTIFY">Draph Solutions S.r.o. is a company incorporated in the Czech Republic (&lsquo;CZ&rsquo;) with the registered company number C 278807, whose registered office address is at P&yacute;chavkov&aacute; 264/2, Pitkovice, 104 00, Praha (the &lsquo;Draph&rsquo;, &lsquo;we&rsquo;, or &lsquo;us&rsquo;).</p>
	<p align="JUSTIFY">We are recognized and provide our services under the trade name &lsquo;<strong>Swipez</strong>&rsquo;.</p>
	<p align="JUSTIFY">We are offering payment services as a small-scale payment service provider (the &lsquo;SSP&rsquo;)<em>.</em>&nbsp; We are authorised and regulated by the Czech National Bank (the &lsquo;CNB&rsquo;).</p>
	<p align="JUSTIFY"><span>Laws of the Czech Republic are applicable and </span>regulate<span> our business activity, provision of </span>our services<span> and our relations with the clients </span>and the CNB.</p>
	<p align="JUSTIFY">We encourage our clients to let us know, regarding any matter, when they feel that their expectations have not been met or they have some concerns about the services provided by us.</p>
	<p align="JUSTIFY">Providing our clients with high standard services is a top priority for us. We strive to build long term relationships with our clients by supporting them at any time and thus we take all complaints very seriously, and our promise to you is that we will investigate and deal with your complaint promptly and fairly.</p>
	<p><span>Below you will find a&nbsp;guidance on the manner we receive, investigate and consider complaints related to the provision of our services.</span></p>
	<p><span>Your complaints shall be considered free of charge.</span></p>
	<p align="JUSTIFY">In case of any complaint, you may have regarding our services, please contact our Customer Support team via e-mail address <a href="mailto:support@swipez.com"><span style="color: #0000ff;"><u>support@swipez.com</u></span></a>, as the first instance in your issues consideration. You may also reach our Customer Support team via your profile in the system. Our Customer Support team may assist with a variety of issues quickly and efficiently or, when necessary, escalate the issue to the appropriate department of Draph with no delay.</p>
	<p align="JUSTIFY">If an issue is not resolved to your satisfaction by our Customer Support team you can submit a formal complaint by email <a href="mailto:info@swipez.eu"><span style="color: #1155cc;"><u>info@swipez.eu</u></span></a>.</p>
	<p align="JUSTIFY">Please note that you should apply to us in writing, specifying the circumstances of the dispute and your claim.</p>
	<p>We expect you will provide us comprehensive information when submitting a complaint:</p>
	<ul class="bullet--list">
		<li>
			<p>your full name,</p>
		</li>
		<li>
			<p>your account number,</p>
		</li>
		<li>
			<p>the phone number associated with your account,</p>
		</li>
		<li>
			<p>the e-mail address associated with your account,</p>
		</li>
		<li>
			<p>full details of the circumstances giving rise to the complaint including a full description of the issue, the date and time that the issue occurred and your preferred method of resolution,</p>
		</li>
		<li>
			<p>enclosed compliant supporting documents will help us to fasten the case investigation and comprehensive review of all related details.</p>
		</li>
	</ul>
	<p align="JUSTIFY">We will acknowledge complaint receipt in writing via e-mail latest within 48 (forty-eight) hours since the complaint is received.</p>
	<p align="JUSTIFY">If you change your mind and wish to withdraw your complaint, please let us know by e-mail or via your profile in the system. The reason for the withdrawal is much appreciated from our side.</p>
	<p align="JUSTIFY">After receiving full details of your complaint, we will promptly consider the matter and will provide you with our final response within 15 (fifteen) Business Days. In case we will need to obtain any additional information to properly address the issue, we will contact you and identify what further information or documentation is required. Please note that any delay arising as a result of the provision of insufficient information with your complaint will not be taken into account when assessing whether a final response has been provided within 15 (fifteen) Business Days.</p>
	<p align="JUSTIFY">If we are unable to provide a final response to your complaint within 15 (fifteen) Business Days, we will contact you to explain the reasons for the delay and specify the date when you will receive a final response from us. In any event, we will provide our final response within 35 (thirty-five) Business Days of the date the complaint was received.</p>
	<p>Our final response will be clearly identified as such and will either:</p>
	<ul class="bullet--list">
		<li>
			<p>Accept your complaint and, if appropriate, offer you funds refund or other compensation,</p>
		</li>
		<li>
			<p>Offer you compensation as a gesture of goodwill without accepting your complaint,</p>
		</li>
		<li>
			<p>Reject your complaint and explain the reasons why the complaint has been rejected.</p>
		</li>
	</ul>
	<p>If you are not satisfied with our response or we have not replied to you after 35 (thirty-five) Business Days, you may be eligible to refer the matter to the Czech National Bank by e-mail to <a href="mailto:podatelna@cnb.cz"><span style="color: #1155cc;"><u>podatelna@cnb.cz</u></span></a>, by post to Senov&aacute;žn&aacute; 3, 115 03 Praha 1, or to the data box &ndash; data box ID: 8tgaiej, or using electronic link <a href="https://www.cnb.cz/en/public/contacts/electronic-form/"><span style="color: #0000ff;"><u>https://www.cnb.cz/en/public/contacts/electronic-form/</u></span></a>.</p>
	<p><span style="font-family: Calibri, serif;"><span style="font-size: small;">You may also apply to the Public Defender of Rights (Ombudsman), </span></span><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span style="font-size: small;"><u><a href="http://www.ochrance.cz/">www.ochrance.cz/</a>, </u></span></span></span><span style="font-family: Calibri, serif;"><span style="font-size: small;">&Uacute;doln&iacute; 39, 602&nbsp;00 Brno, e-mail address </span></span><a href="mailto:podatelna@ochrance.cz"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span style="font-size: small;"><u>podatelna@ochrance.cz</u></span></span></span></a><span style="font-family: Calibri, serif;"><span style="font-size: small;">, data box ID: </span></span><span style="font-family: Calibri, serif;"><span style="font-size: small;">jz5adky, </span></span><span style="font-family: Calibri, serif;"><span style="font-size: small;">(+420) 542 542&nbsp;888.</span></span></p>
	<p>We work hard to make sure you never have cause to complain about the services we provide!</p>
</DocumentLayout>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API

import { DocumentLayout } from "@/layout";
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
// import HomeAdapterService from "./home/adapter/HomeAdapterService";

// const adapter = new HomeAdapterService();

onMounted(async () => { });
const onShowAnswer = (id: string) => { };
</script>

<style lang="less" scoped>
.h1--hero {
	width: 100%;
}

.global--padding {
	p {
		color: #fff;
		margin-bottom:0px
	}

	ul {
		li::marker {
			color: #fff;
			font-weight: bold;
		}
	}

	h2,
	h3 {
		padding-top: 1em;
		padding-bottom: .5em;
		color: #fff;
		text-align: left !important;

	}

	ol {
		margin-left: 20px;

		li::marker {
			color: #fff;
			font-weight: bold;
			font-size: 3em;
			font-family: Made Outer Sans, sans-serif;
		}

		&.litle li::marker {
			font-size: 14px;

		}
	}
}
</style>
