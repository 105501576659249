import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import LegalCompanySaveDataRequest from '../../../../../identification-services/models/legal-company/LegalCompanySaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import LegalCompanyGetDataResponse from '../../../../../identification-services/models/legal-company/LegalCompanyGetDataResponse';

export default class LegalCompanyDataWsController {
	webApiService: WebApiService;
	GetCompanyDataEvent = new Events<WsResponseModelT<LegalCompanyGetDataResponse>>();
	SaveCompanyDataEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LegalCompanyDataWsController', 'GetCompanyData', (res: WsResponseModelT<LegalCompanyGetDataResponse>) => {
			self.GetCompanyDataEvent.trigger(res);
		});
		webApiService.on('LegalCompanyDataWsController', 'SaveCompanyData', (res: WsResponseModelT<Boolean>) => {
			self.SaveCompanyDataEvent.trigger(res);
		});
	}
	GetCompanyData() { 	
        this.webApiService.send('LegalCompanyDataWsController', 'GetCompanyData' );
    }
	SaveCompanyData(request: LegalCompanySaveDataRequest) { 	
        this.webApiService.send('LegalCompanyDataWsController', 'SaveCompanyData' , request);
    }
	 // async methods
	async getCompanyDataAsync(): Promise<LegalCompanyGetDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCompanyData.bind(this), this.GetCompanyDataEvent)();
	}
	async saveCompanyDataAsync(request: LegalCompanySaveDataRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.SaveCompanyData.bind(this), this.SaveCompanyDataEvent)(request);
	}
}
