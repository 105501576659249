import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import ChangePasswordRequest from '../../../../../aba/cabinet/web/models/ChangePasswordRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';

export default class SecurityWsController {
	webApiService: WebApiService;
	ChangePasswordEvent = new Events<WsResponseModelT<Boolean>>();
	ChangeDefenseEvent = new Events<WsResponseModelT<Boolean>>();
	CheckDefenseEvent = new Events<WsResponseModelT<Boolean>>();
	GetQrImageEvent = new Events<WsResponseModelT<String>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('SecurityWsController', 'ChangePassword', (res: WsResponseModelT<Boolean>) => {
			self.ChangePasswordEvent.trigger(res);
		});
		webApiService.on('SecurityWsController', 'ChangeDefense', (res: WsResponseModelT<Boolean>) => {
			self.ChangeDefenseEvent.trigger(res);
		});
		webApiService.on('SecurityWsController', 'CheckDefense', (res: WsResponseModelT<Boolean>) => {
			self.CheckDefenseEvent.trigger(res);
		});
		webApiService.on('SecurityWsController', 'GetQrImage', (res: WsResponseModelT<String>) => {
			self.GetQrImageEvent.trigger(res);
		});
	}
	ChangePassword(request: ChangePasswordRequest) { 	
        this.webApiService.send('SecurityWsController', 'ChangePassword' , request);
    }
	ChangeDefense(twoAuth: boolean = false) { 	
        this.webApiService.send('SecurityWsController', 'ChangeDefense' , twoAuth);
    }
	CheckDefense() { 	
        this.webApiService.send('SecurityWsController', 'CheckDefense' );
    }
	GetQrImage() { 	
        this.webApiService.send('SecurityWsController', 'GetQrImage' );
    }
	 // async methods
	async changePasswordAsync(request: ChangePasswordRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ChangePassword.bind(this), this.ChangePasswordEvent)(request);
	}
	async changeDefenseAsync(twoAuth: boolean = false): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ChangeDefense.bind(this), this.ChangeDefenseEvent)(twoAuth);
	}
	async checkDefenseAsync(): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckDefense.bind(this), this.CheckDefenseEvent)();
	}
	async getQrImageAsync(): Promise<String> {
		return EventToAsyncDecorator.getInstance().bind(this.GetQrImage.bind(this), this.GetQrImageEvent)();
	}
}
