import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import LoginRequest from '../../../../../aba/public/services/models/login/LoginRequest';
import CheckUniqueEmailRequest from '../../../../../aba/public/services/models/registration/CheckUniqueEmailRequest';
import RegistertrationStartRequest from '../../../../../aba/public/services/models/registration/RegistertrationStartRequest';
import RegistrationRequest from '../../../../../aba/public/services/models/registration/RegistrationRequest';
import RecoverPasswordRequestRequest from '../../../../../aba/public/services/models/restore-password/RecoverPasswordRequestRequest';
import RestorePasswordRequest from '../../../../../aba/public/services/models/restore-password/RestorePasswordRequest';
import ConfirmCodeRequest from '../../../../../aba/public/services/models/security/ConfirmCodeRequest';
import TwoFactorConfirmCodeRequest from '../../../../../aba/public/services/models/login/TwoFactorConfirmCodeRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import CurrentUserResponse from '../../../../../aba/cabinet/web/models/CurrentUserResponse';
import LoginResponse from '../../../../../aba/public/services/models/login/LoginResponse';
import TwoFactorConfirmCodeResponse from '../../../../../aba/public/services/models/login/TwoFactorConfirmCodeResponse';

export default class AuthWsController {
	webApiService: WebApiService;
	GetCurrentUserEvent = new Events<WsResponseModelT<CurrentUserResponse>>();
	LoginEvent = new Events<WsResponseModelT<LoginResponse>>();
	CheckUniqueEmalEvent = new Events<WsResponseModelT<Boolean>>();
	RegisterStartEvent = new Events<WsResponseModelT<Boolean>>();
	RegisterEndEvent = new Events<WsResponseModelT<Boolean>>();
	RecoverPasswordRequestEvent = new Events<WsResponseModelT<Boolean>>();
	RecoverPasswordEvent = new Events<WsResponseModelT<Boolean>>();
	TwoFactorAuthenticationEvent = new Events<WsResponseModelT<Boolean>>();
	ConfrimCodeEvent = new Events<WsResponseModelT<TwoFactorConfirmCodeResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AuthWsController', 'GetCurrentUser', (res: WsResponseModelT<CurrentUserResponse>) => {
			self.GetCurrentUserEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'Login', (res: WsResponseModelT<LoginResponse>) => {
			self.LoginEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'CheckUniqueEmal', (res: WsResponseModelT<Boolean>) => {
			self.CheckUniqueEmalEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RegisterStart', (res: WsResponseModelT<Boolean>) => {
			self.RegisterStartEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RegisterEnd', (res: WsResponseModelT<Boolean>) => {
			self.RegisterEndEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RecoverPasswordRequest', (res: WsResponseModelT<Boolean>) => {
			self.RecoverPasswordRequestEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RecoverPassword', (res: WsResponseModelT<Boolean>) => {
			self.RecoverPasswordEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'TwoFactorAuthentication', (res: WsResponseModelT<Boolean>) => {
			self.TwoFactorAuthenticationEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'ConfrimCode', (res: WsResponseModelT<TwoFactorConfirmCodeResponse>) => {
			self.ConfrimCodeEvent.trigger(res);
		});
	}
	GetCurrentUser() { 	
        this.webApiService.send('AuthWsController', 'GetCurrentUser' );
    }
	Login(request: LoginRequest) { 	
        this.webApiService.send('AuthWsController', 'Login' , request);
    }
	CheckUniqueEmal(request: CheckUniqueEmailRequest) { 	
        this.webApiService.send('AuthWsController', 'CheckUniqueEmal' , request);
    }
	RegisterStart(request: RegistertrationStartRequest) { 	
        this.webApiService.send('AuthWsController', 'RegisterStart' , request);
    }
	RegisterEnd(request: RegistrationRequest) { 	
        this.webApiService.send('AuthWsController', 'RegisterEnd' , request);
    }
	RecoverPasswordRequest(request: RecoverPasswordRequestRequest) { 	
        this.webApiService.send('AuthWsController', 'RecoverPasswordRequest' , request);
    }
	RecoverPassword(request: RestorePasswordRequest) { 	
        this.webApiService.send('AuthWsController', 'RecoverPassword' , request);
    }
	TwoFactorAuthentication(request: ConfirmCodeRequest) { 	
        this.webApiService.send('AuthWsController', 'TwoFactorAuthentication' , request);
    }
	ConfrimCode(request: TwoFactorConfirmCodeRequest) { 	
        this.webApiService.send('AuthWsController', 'ConfrimCode' , request);
    }
	 // async methods
	async getCurrentUserAsync(): Promise<CurrentUserResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCurrentUser.bind(this), this.GetCurrentUserEvent)();
	}
	async loginAsync(request: LoginRequest): Promise<LoginResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.Login.bind(this), this.LoginEvent)(request);
	}
	async checkUniqueEmalAsync(request: CheckUniqueEmailRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckUniqueEmal.bind(this), this.CheckUniqueEmalEvent)(request);
	}
	async registerStartAsync(request: RegistertrationStartRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.RegisterStart.bind(this), this.RegisterStartEvent)(request);
	}
	async registerEndAsync(request: RegistrationRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.RegisterEnd.bind(this), this.RegisterEndEvent)(request);
	}
	async recoverPasswordRequestAsync(request: RecoverPasswordRequestRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.RecoverPasswordRequest.bind(this), this.RecoverPasswordRequestEvent)(request);
	}
	async recoverPasswordAsync(request: RestorePasswordRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.RecoverPassword.bind(this), this.RecoverPasswordEvent)(request);
	}
	async twoFactorAuthenticationAsync(request: ConfirmCodeRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.TwoFactorAuthentication.bind(this), this.TwoFactorAuthenticationEvent)(request);
	}
	async confrimCodeAsync(request: TwoFactorConfirmCodeRequest): Promise<TwoFactorConfirmCodeResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.ConfrimCode.bind(this), this.ConfrimCodeEvent)(request);
	}
}
