import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import { HomePage, PrivacyPolicy, TermsAndConditions, ComplaintsDisclaimer, SafeguardingDisclaimer, CryptocurrencyExchangeNotice } from '@/pages';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/complaints-disclaimer',
    name: 'complaints-disclaimer',
    component: ComplaintsDisclaimer,
  },
  {
    path: '/safeguarding-disclaimer',
    name: 'safeguarding-disclaimer',
    component: SafeguardingDisclaimer,
  },
  {
    path: '/cryptocurrency-exchange-notice',
    name: 'cryptocurrency-exchange-notice',
    component: CryptocurrencyExchangeNotice,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
