import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import EnumPaymentServiceType from '../../../../../aba/data/enums/EnumPaymentServiceType';

export default class PaymentSettingsWsController {
	webApiService: WebApiService;
	GetUserPaymentServicesEvent = new Events<WsResponseModelT<Array<EnumPaymentServiceType>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('PaymentSettingsWsController', 'GetUserPaymentServices', (res: WsResponseModelT<Array<EnumPaymentServiceType>>) => {
			self.GetUserPaymentServicesEvent.trigger(res);
		});
	}
	GetUserPaymentServices() { 	
        this.webApiService.send('PaymentSettingsWsController', 'GetUserPaymentServices' );
    }
	 // async methods
	async getUserPaymentServicesAsync(): Promise<Array<EnumPaymentServiceType>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUserPaymentServices.bind(this), this.GetUserPaymentServicesEvent)();
	}
}
