import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import VirtpayPayinRequest from '../../../../../virtpay-transactions-services/models/payin/VirtpayPayinRequest';
import VirtpayPayoutRequest from '../../../../../virtpay-transactions-services/models/payout/VirtpayPayoutRequest';
import VirtpayPayoutHttpRequest from '../../../../../aba/cabinet/web/models/VirtpayPayoutHttpRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import VirtpayPayinResponse from '../../../../../virtpay-transactions-services/models/payin/VirtpayPayinResponse';
import InfoForVirtpay from '../../../../../transaction-services/models/transfer-info-service/InfoForVirtpay';
import VirtpayPayoutResponse from '../../../../../virtpay-transactions-services/models/payout/VirtpayPayoutResponse';

export default class VirtpayWsController {
	webApiService: WebApiService;
	PayinEvent = new Events<WsResponseModelT<VirtpayPayinResponse>>();
	CheckVirtpayTransferEvent = new Events<WsResponseModelT<InfoForVirtpay>>();
	PayoutEvent = new Events<WsResponseModelT<VirtpayPayoutResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('VirtpayWsController', 'Payin', (res: WsResponseModelT<VirtpayPayinResponse>) => {
			self.PayinEvent.trigger(res);
		});
		webApiService.on('VirtpayWsController', 'CheckVirtpayTransfer', (res: WsResponseModelT<InfoForVirtpay>) => {
			self.CheckVirtpayTransferEvent.trigger(res);
		});
		webApiService.on('VirtpayWsController', 'Payout', (res: WsResponseModelT<VirtpayPayoutResponse>) => {
			self.PayoutEvent.trigger(res);
		});
	}
	Payin(request: VirtpayPayinRequest) { 	
        this.webApiService.send('VirtpayWsController', 'Payin' , request);
    }
	CheckVirtpayTransfer(request: VirtpayPayoutRequest) { 	
        this.webApiService.send('VirtpayWsController', 'CheckVirtpayTransfer' , request);
    }
	Payout(request: VirtpayPayoutHttpRequest) { 	
        this.webApiService.send('VirtpayWsController', 'Payout' , request);
    }
	 // async methods
	async payinAsync(request: VirtpayPayinRequest): Promise<VirtpayPayinResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.Payin.bind(this), this.PayinEvent)(request);
	}
	async checkVirtpayTransferAsync(request: VirtpayPayoutRequest): Promise<InfoForVirtpay> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckVirtpayTransfer.bind(this), this.CheckVirtpayTransferEvent)(request);
	}
	async payoutAsync(request: VirtpayPayoutHttpRequest): Promise<VirtpayPayoutResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.Payout.bind(this), this.PayoutEvent)(request);
	}
}
