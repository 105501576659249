import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import SwiftPayoutRequest from '../../../../../transaction-services/models/transaction-operations/SwiftPayoutRequest';
import ModelTransferSwift from '../../../../../i-public-service/aba-finance/models/ModelTransferSwift';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import GetSwiftTemplatesResponse from '../../../../../transaction-services/models/transfer-template-service/GetSwiftTemplatesResponse';

export default class SwiftWsController {
	webApiService: WebApiService;
	GetTemplatesEvent = new Events<WsResponseModelT<GetSwiftTemplatesResponse>>();
	GetCommissionForSwiftPaymentEvent = new Events<WsResponseModelT<number>>();
	CheckSwiftTransferEvent = new Events<WsResponseModelT<Boolean>>();
	TransferSwiftEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('SwiftWsController', 'GetTemplates', (res: WsResponseModelT<GetSwiftTemplatesResponse>) => {
			self.GetTemplatesEvent.trigger(res);
		});
		webApiService.on('SwiftWsController', 'GetCommissionForSwiftPayment', (res: WsResponseModelT<number>) => {
			self.GetCommissionForSwiftPaymentEvent.trigger(res);
		});
		webApiService.on('SwiftWsController', 'CheckSwiftTransfer', (res: WsResponseModelT<Boolean>) => {
			self.CheckSwiftTransferEvent.trigger(res);
		});
		webApiService.on('SwiftWsController', 'TransferSwift', (res: WsResponseModelT<Boolean>) => {
			self.TransferSwiftEvent.trigger(res);
		});
	}
	GetTemplates() { 	
        this.webApiService.send('SwiftWsController', 'GetTemplates' );
    }
	GetCommissionForSwiftPayment(amount: number) { 	
        this.webApiService.send('SwiftWsController', 'GetCommissionForSwiftPayment' , amount);
    }
	CheckSwiftTransfer(model: SwiftPayoutRequest) { 	
        this.webApiService.send('SwiftWsController', 'CheckSwiftTransfer' , model);
    }
	TransferSwift(model: ModelTransferSwift) { 	
        this.webApiService.send('SwiftWsController', 'TransferSwift' , model);
    }
	 // async methods
	async getTemplatesAsync(): Promise<GetSwiftTemplatesResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTemplates.bind(this), this.GetTemplatesEvent)();
	}
	async getCommissionForSwiftPaymentAsync(amount: number): Promise<number> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCommissionForSwiftPayment.bind(this), this.GetCommissionForSwiftPaymentEvent)(amount);
	}
	async checkSwiftTransferAsync(model: SwiftPayoutRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckSwiftTransfer.bind(this), this.CheckSwiftTransferEvent)(model);
	}
	async transferSwiftAsync(model: ModelTransferSwift): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.TransferSwift.bind(this), this.TransferSwiftEvent)(model);
	}
}
