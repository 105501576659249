import { WebApiService } from '../../../../plugins/webApiService'
import { Events } from '../../../../plugins/events'
import EventToAsyncDecorator from '../../../../plugins/eventToAsyncDecorator'
import EnumLang from '../../../../i-public-service/aba-finance/models/enums/EnumLang';
import MailBankModel from '../../../../i-public-service/aba-finance/models/support/MailBankModel';
import WsResponseModelT from '../../../../schema-builder-server/models/WsResponseModelT';
import FAQCategoryModel from '../../../../i-public-service/aba-finance/models/support/FAQCategoryModel';
import FAQQuestionModel from '../../../../i-public-service/aba-finance/models/support/FAQQuestionModel';

export default class SupportWsController {
	webApiService: WebApiService;
	GetCategoriesEvent = new Events<WsResponseModelT<Array<FAQCategoryModel>>>();
	SendMessageEvent = new Events<WsResponseModelT<Boolean>>();
	GetPopularQuestionsEvent = new Events<WsResponseModelT<Array<FAQQuestionModel>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('SupportWsController', 'GetCategories', (res: WsResponseModelT<Array<FAQCategoryModel>>) => {
			self.GetCategoriesEvent.trigger(res);
		});
		webApiService.on('SupportWsController', 'SendMessage', (res: WsResponseModelT<Boolean>) => {
			self.SendMessageEvent.trigger(res);
		});
		webApiService.on('SupportWsController', 'GetPopularQuestions', (res: WsResponseModelT<Array<FAQQuestionModel>>) => {
			self.GetPopularQuestionsEvent.trigger(res);
		});
	}
	GetCategories(langId: EnumLang) { 	
        this.webApiService.send('SupportWsController', 'GetCategories' , langId);
    }
	SendMessage(model: MailBankModel) { 	
        this.webApiService.send('SupportWsController', 'SendMessage' , model);
    }
	GetPopularQuestions(langId: EnumLang) { 	
        this.webApiService.send('SupportWsController', 'GetPopularQuestions' , langId);
    }
	 // async methods
	async getCategoriesAsync(langId: EnumLang): Promise<Array<FAQCategoryModel>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCategories.bind(this), this.GetCategoriesEvent)(langId);
	}
	async sendMessageAsync(model: MailBankModel): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.SendMessage.bind(this), this.SendMessageEvent)(model);
	}
	async getPopularQuestionsAsync(langId: EnumLang): Promise<Array<FAQQuestionModel>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetPopularQuestions.bind(this), this.GetPopularQuestionsEvent)(langId);
	}
}
