import { WebApiService } from '../../../../../../plugins/webApiService'
import { Events } from '../../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../../plugins/eventToAsyncDecorator'
import AcquiringInitPayinRequest from '../../../../../../transaction-services/models/acquirings/AcquiringInitPayinRequest';
import GetTransactionsRequest from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsRequest';
import WsResponseModelT from '../../../../../../schema-builder-server/models/WsResponseModelT';
import AcquiringInitPayinResponse from '../../../../../../transaction-services/models/acquirings/AcquiringInitPayinResponse';
import GetTransactionsResponse from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsResponse';
import ModelTransaction from '../../../../../../i-public-service/aba-finance/models/ModelTransaction';
import AcquiringPayoutTemplateListResponse from '../../../../../../transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse';

export default class NaturalPaymentsWsController {
	webApiService: WebApiService;
	AcquiringPayinEvent = new Events<WsResponseModelT<AcquiringInitPayinResponse>>();
	GetHistoryEvent = new Events<WsResponseModelT<GetTransactionsResponse>>();
	UpdateCardTransactionEvent = new Events<WsResponseModelT<Array<ModelTransaction>>>();
	GetAcquiringTemplatesEvent = new Events<WsResponseModelT<AcquiringPayoutTemplateListResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('NaturalPaymentsWsController', 'AcquiringPayin', (res: WsResponseModelT<AcquiringInitPayinResponse>) => {
			self.AcquiringPayinEvent.trigger(res);
		});
		webApiService.on('NaturalPaymentsWsController', 'GetHistory', (res: WsResponseModelT<GetTransactionsResponse>) => {
			self.GetHistoryEvent.trigger(res);
		});
		webApiService.on('NaturalPaymentsWsController', 'UpdateCardTransaction', (res: WsResponseModelT<Array<ModelTransaction>>) => {
			self.UpdateCardTransactionEvent.trigger(res);
		});
		webApiService.on('NaturalPaymentsWsController', 'GetAcquiringTemplates', (res: WsResponseModelT<AcquiringPayoutTemplateListResponse>) => {
			self.GetAcquiringTemplatesEvent.trigger(res);
		});
	}
	AcquiringPayin(request: AcquiringInitPayinRequest) { 	
        this.webApiService.send('NaturalPaymentsWsController', 'AcquiringPayin' , request);
    }
	GetHistory(request: GetTransactionsRequest) { 	
        this.webApiService.send('NaturalPaymentsWsController', 'GetHistory' , request);
    }
	UpdateCardTransaction(dateTime: Date) { 	
        this.webApiService.send('NaturalPaymentsWsController', 'UpdateCardTransaction' , dateTime);
    }
	GetAcquiringTemplates() { 	
        this.webApiService.send('NaturalPaymentsWsController', 'GetAcquiringTemplates' );
    }
	 // async methods
	async acquiringPayinAsync(request: AcquiringInitPayinRequest): Promise<AcquiringInitPayinResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.AcquiringPayin.bind(this), this.AcquiringPayinEvent)(request);
	}
	async getHistoryAsync(request: GetTransactionsRequest): Promise<GetTransactionsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetHistory.bind(this), this.GetHistoryEvent)(request);
	}
	async updateCardTransactionAsync(dateTime: Date): Promise<Array<ModelTransaction>> {
		return EventToAsyncDecorator.getInstance().bind(this.UpdateCardTransaction.bind(this), this.UpdateCardTransactionEvent)(dateTime);
	}
	async getAcquiringTemplatesAsync(): Promise<AcquiringPayoutTemplateListResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetAcquiringTemplates.bind(this), this.GetAcquiringTemplatesEvent)();
	}
}
