export default {
  Account: 'Účet',
  Pricing: 'Ceník',
  FAQ: 'Často kladené dotazy',
  Contact: 'Kontakt',
  'Log in': 'Přihlásit se',
  'UNITED BANKING': "UNITED <br>BANKING",
  'Connect your partners into unified ecosystem for rapid and secure fund flows': 'Spojte své partnery do jednotného ekosystému <br />pro rychlé a bezpečné tok finančních prostředků',
  header: {
    'Sign Up': 'Zaregistrovat se',
  },
  account: {
    'PERSONAL AND BUSINESS ACCOUNT': 'OSOBNÍ<br />A FIREMNÍ<br> ÚČET',
    'Manage all your financial transactions, make and receive payments. All-in-one space.': 'Spravujte všechny své finanční transakce,<br> provádějte a přijímejte platby.<br> Vše na jednom místě.',
    'Create Account': 'Vytvořit účet',
    'Fast online application and onboarding': 'Rychlá online žádost a registrace',
    'Execute all banking tasks at lightspeed': 'Provádějte všechny bankovní úkoly bleskovou rychlostí',
    'SEPA & SWIFT for easy fund transfers globally': 'SEPA & SWIFT pro snadné převody peněz po celém světě',
    'Crypto payments and investments': 'Krypto platby a investice',
    'Personalized support available anytime': 'Personalizovaná podpora k dispozici kdykoliv',
    'TOP-TIER SECURITY': 'NEJVYŠŠÍ ÚROVEŇ ZABEZPEČENÍ',
    'Trust': 'Důvěřujte našim špičkovým bezpečnostním protokolům, které vám poskytnou klid, abyste se mohli s jistotou věnovat správě svých financí.',
    'SMOOTH TRANSACTIONS': 'HLADKÉ TRANSAKCE',
    'INTEGRATED ECOSYSTEM': 'INTEGROVANÝ EKOSYSTÉM',
    'Our platform': 'Naše platforma propojuje všechny vaše partnery, zaměstnance a dodavatele do jednoho jednotného centra. Zažijte bezproblémovou spolupráci a zefektivněnou komunikaci napříč celou vaší sítí.',
    'Experience': 'Zažijte bezproblémové a efektivní transakce jako nikdy předtím. Naše platforma zajišťuje, že každá platba a bankovní úkol jsou zpracovány rychle, bezpečně a bez obtíží.',
  },
  'EXPLORE A COMPLETE FINANCIAL PLATFORM POWERED BY BANK ACCOUNT': 'PROZKOUMEJTE KOMPLETNÍ FINANČNÍ PLATFORMU POHÁNĚNOU BANKOVNÍM ÚČTEM',
  pricing: {
    'EXPLORE OUR PRICING': 'PROZKOUMEJTE NÁŠ CENÍK',
    'BUSINESS': 'FIREMNÍ',
    'PERSONAL': 'OSOBNÍ',
    'Handling of documents and opening a customer account': 'Zpracování dokumentů a otevření zákaznického účtu',
    'Account monthly service fee': 'Měsíční poplatek za vedení účtu',
    'Incoming internal paymentt': 'Příchozí vnitřní platba',
    'Incoming SEPA-paymen': 'Příchozí SEPA platba',
    'Incoming Swift payment': 'Příchozí Swift platba',
    'Internal outgoing payment between own accounts within Swipez': 'Odchozí vnitřní platba mezi vlastními účty v rámci Swipez',
    'Internal outgoing payment within Swipez': 'Odchozí vnitřní platba v rámci Swipez',
    'Outgoing SEPA-payment': 'Odchozí SEPA platba',
    'Outgoing Swift paymen': 'Odchozí Swift platba',
    'Free of change': 'Bez poplatku',
  },
  questtions: {
    'FREQUENTLY ASKED QUESTIONS': 'ČASTO<br>KLADENÉ OTÁZKY',
    'What country is Swipez registered at?': 'V jaké zemi je Swipez registrován?',
    'How safe is Swipez service?': 'Jak bezpečná je služba Swipez?',
    'What documents must be presented for opening an account?': 'Jaké dokumenty je nutné předložit pro otevření účtu?',
    'When will I get access to the online bank?': 'Kdy získám přístup k online bankovnictví?',
    'Residents of what countries may not open an account?': 'Obyvatelé kterých zemí nemohou otevřít účet?',
  },
  footer: {
    'NEED OUR TEAM HELP?': 'POTŘEBUJETE POMOC<br> NAŠEHO TÝMU?',
    'Privacy Policy': 'Zásady ochrany osobních údajů',
    'Terms and Conditions': 'Obchodní podmínky',
    'Claims Procedure': 'Postup při reklamaci',
    'Cryptocurrency Exchange Notice': 'Upozornění na směnu kryptoměn',
    'desk0': 'Chcete-li zjistit, která společnost Revolut vás obsluhuje nebo máte-li jakékoli dotazy, kontaktujte nás prostřednictvím chatu v aplikaci.',
    'desk1': 'Revolut Ltd je registrována v Anglii a Walesu (č. 08804411), 7 Westferry Circus, Canary Wharf, Londýn, Anglie, E14 4HD a je autorizována Financial Conduct Authority podle Electronic Money Regulations 2011 (referenční číslo 900562). Revolut Travel Ltd je autorizována Financial Conduct Authority k provádění distribuce pojištění (č. FCA: 780586). Naše pojišťovací produkty jsou zajišťovány Revolut Travel Ltd a Revolut Ltd, která je jmenovaným zástupcem Revolut Travel Ltd. Produkty obchodování s akciemi společnosti Revolut poskytuje Revolut Trading Ltd (č. 832790), jmenovaný zástupce Resolution Compliance Ltd, která je autorizována a regulována Financial Conduct Authority.',
    'desk2': 'Revolut Ltd je také registrována u Financial Conduct Authority k poskytování služeb s kryptoměnami podle Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017. Služby s drahými kovy jsou poskytovány Revolut Ltd a nejsou regulovány Financial Conduct Authority.',
  }
};
