import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import CryptPayoutRequest from '../../../../../transaction-services/models/transaction-operations/CryptPayoutRequest';
import ModelTransferCryptPayout from '../../../../../i-public-service/aba-finance/models/ModelTransferCryptPayout';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import InfoForCryptPayout from '../../../../../transaction-services/models/transfer-info-service/InfoForCryptPayout';

export default class CryptPayoutWsController {
	webApiService: WebApiService;
	CheckTransferEvent = new Events<WsResponseModelT<InfoForCryptPayout>>();
	TransferEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('CryptPayoutWsController', 'CheckTransfer', (res: WsResponseModelT<InfoForCryptPayout>) => {
			self.CheckTransferEvent.trigger(res);
		});
		webApiService.on('CryptPayoutWsController', 'Transfer', (res: WsResponseModelT<Boolean>) => {
			self.TransferEvent.trigger(res);
		});
	}
	CheckTransfer(model: CryptPayoutRequest) { 	
        this.webApiService.send('CryptPayoutWsController', 'CheckTransfer' , model);
    }
	Transfer(model: ModelTransferCryptPayout) { 	
        this.webApiService.send('CryptPayoutWsController', 'Transfer' , model);
    }
	 // async methods
	async checkTransferAsync(model: CryptPayoutRequest): Promise<InfoForCryptPayout> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckTransfer.bind(this), this.CheckTransferEvent)(model);
	}
	async transferAsync(model: ModelTransferCryptPayout): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.Transfer.bind(this), this.TransferEvent)(model);
	}
}
