<template>
<DocumentLayout>
	<h1 class="h1--hero">
		<div class="hero-h-wrapper">
			<div data-w-id="a57a7ba6-1456-df65-733e-c340ef917366" class="h--super-large">
				SAFEGUARDING
			</div>
		</div>
		<div class="hero-h-wrapper">
			<div data-w-id="201b457d-0993-bca7-76e5-88cdccd4a159" class="h--super-large">
				DISCLAIMER
			</div>
		</div>
	</h1>
	<h2>How We Keep Your Funds Safe</h2>
	<p>It&rsquo;s of our great importance that you carefully read information provided below and fully understand how we protect your funds held with us!</p>
	<p>Draph Solutions S.r.o. is a company incorporated in the Czech Republic (&lsquo;CZ&rsquo;) with еру registered company number C 278807, whose registered office address is at P&yacute;chavkov&aacute; 264/2, Pitkovice, 104 00, Praha (the &lsquo;Draph&rsquo;, &lsquo;we&rsquo;, or &lsquo;us&rsquo;).</p>
	<p>We are recognized and provide our services under the trade name &lsquo;<strong>Swipez</strong>&rsquo;.</p>
	<p>We are offering payment services as a small-scale payment service provider (the &lsquo;SSP&rsquo;)<em>.</em>&nbsp;</p>
	<p>We are authorised and regulated by the Czech National Bank (the &lsquo;CNB&rsquo;).</p>
	<p><span>Las of the Czech Republic are applicable and </span>regulate<span> our business activity, provision of our </span>s<span>ervices and our relations with the clients </span>and the CNB.</p>
	<p>I<span>t is important to </span>understand that<span> Draph </span><span><em>is not a bank</em></span> and we are not offering banking services<span>. In this regard </span>your funds held with us are not protected by the <span>Czech National Bank Compensation Schemes.</span></p>
	<p>Nevertheless, protection of your funds held with us and keeping them safe is one of our core obligations. <span>Draph has defined protection of clients&rsquo; funds as a cornerstone task.</span></p>
	<p>For this purpose, Draph uses safeguarding as a key method of clients&rsquo; funds protection and applies adequate measures to ensure that in case of Draph insolvency, clients&rsquo; funds are returned in full in a timely and orderly manner.</p>
	<p>Safeguarding method ensures that on insolvency, claims of our clients shall be paid from the asset pool formed from the funds segregated from the Draph working capital and other sources of funds and proceeds.</p>
	<p><span>With the aim to effectively manage safeguarding measures to be applied to protect your funds </span>held<span> with us and related risks, and negative consequences which may arise, we have established robust internal policies and procedures</span> adhering applicable laws and regulations, as well best practice recognized common standards.</p>
	<p><span>We safeguard all c</span>lient<span>s&rsquo; funds regardless of their amount or country, or origin/destination, also unidentified funds, </span>by means<span> of segregating </span>client<span>s&rsquo; funds, thus keeping them separate from other </span>asset pools<span> and all other sources of funds of the Draph. </span></p>
	<p>We <span>ensure all clients&rsquo; funds are properly and adequately managed on a </span>daily <span>basis. </span>We promptly allocate your funds&nbsp;just funds are received.</p>
	<p><span>As soon as any safeguarded funds are received into our accounts, we make an equivalent transaction moving the safeguarding funds into our designated safeguarding account that </span>is<span> held </span>with the authorized Czech bank<span>. Equally, when you make payment from the account held with us, we automatically reduce the balance held in our safeguarding account in order to make the outgoing payment. Records are kept of the underlying client balances both by us, and also our safeguarding account provider, so there is a real-time and accurate record of the clients</span>&rsquo;<span> funds </span>held<span> with us.</span></p>
	<p><span>We place your funds in a safeguarding account we </span>hold<span> with an authorized Czech </span>bank<span> &ndash; currently the</span> <strong>Fio Banka a.s.</strong> (the Czech Republic, the <span>European Union), </span><a href="https://www.fio.cz/"><span style="color: #1155cc;"><u>https://www.fio.cz/</u></span></a>.</p>
	<p><span>Our safeguarding obligations shall remain in full force and effect till your funds are </span>held<span> with us. </span></p>
	<p>Safeguarded funds are inaccessible to our creditors, our financial institutions or any other third parties.</p>
	<p>In case of our insolvency, clients&rsquo; funds will always be available to the clients in the normal course of our business and shall be paid out first from the safeguarding account by the appointed insolvency administrator.</p>
	<p>Wealways take adequate measures to ensure that in case Draph fails, your funds will be paid out in a timely and orderly manner.</p>
	<p><span>We provide overall control </span>over the implementation of safeguarding requirements that apply to us,<span> our employees, agents and distributors. </span>We apply sufficient organizational measures to effectively manage the risks that may arise from negligence or poor administration, as well as fraud and misuse. For these purposes we provide regular safeguarding risk monitoring, as well as regularly revise safeguarding issues and provide its update on an ongoing basis.</p>
	<h4><span style="font-family: Calibri, serif;"><span style="font-size: small;">In case of any questions or any lack of understanding regarding funds held with us and funds protection, please do not hesitate to contact us by sending your questions to our Customer Support team - </span></span><a href="about:blank"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span style="font-size: small;"><u>support@swipez.eu.</u></span></span></span></a><span style="font-family: Calibri, serif;"><span style="font-size: small;">Our highly professional teams will provide you with explicit and comprehensive explanations regarding clients&rsquo; funds safeguarding issues. </span></span></h4>
	<h4><span style="font-family: Calibri, serif;"><span style="font-size: small;">You can read more on the Czech National Bank Compensation Schemes on the CNB website </span></span><a href="https://www.cnb.cz/en/faq/How-bank-deposits-are-insured-in-the-Czech-Republic/"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span style="font-size: small;"><u>https://www.cnb.cz/en/faq/How-bank-deposits-are-insured-in-the-Czech-Republic/</u></span></span></span></a><span style="font-family: Calibri, serif;"><span style="font-size: small;"> or contact CNB</span></span> <span style="font-family: Calibri, serif;"><span style="font-size: small;">by e-mail address </span></span><a href="mailto:podatelna@cnb.cz"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span style="font-size: small;"><u>podatelna@cnb.cz</u></span></span></span></a><span style="font-family: Calibri, serif;"><span style="font-size: small;"> or by post to Senov&aacute;žn&aacute; 3, 115 03, Praha 1.</span></span></h4>
	<h4>&nbsp;</h4>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>

</DocumentLayout>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API

import { DocumentLayout } from "@/layout";
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
// import HomeAdapterService from "./home/adapter/HomeAdapterService";

// const adapter = new HomeAdapterService();

onMounted(async () => { });
const onShowAnswer = (id: string) => { };
</script>

<style lang="less" scoped>
.h1--hero {
	width: 100%;
}

.global--padding {
	p {
		color: #fff
	}

	ul {
		li::marker {
			color: #fff;
			font-weight: bold;
		}
	}

	h2,
	h3 {
		padding-top: 1em;
		padding-bottom: .5em;
		color: #fff;
		text-align: left !important;

	}

	h4 {
		color: #fff
	}

	ol {
		margin-left: 20px;

		li::marker {
			color: #fff;
			font-weight: bold;
			font-size: 3em;
			font-family: Made Outer Sans, sans-serif;
		}

		&.litle li::marker {
			font-size: 14px;

		}
	}
}
</style>
