import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import FincheckerLivenessStatusResponse from '../../../../../identification-services/models/finchecker/FincheckerLivenessStatusResponse';

export default class FincheckerWsController {
	webApiService: WebApiService;
	IsLivenessInitEvent = new Events<WsResponseModelT<FincheckerLivenessStatusResponse>>();
	LivenessStartEvent = new Events<WsResponseModelT<String>>();
	LivenessSaveResultEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('FincheckerWsController', 'IsLivenessInit', (res: WsResponseModelT<FincheckerLivenessStatusResponse>) => {
			self.IsLivenessInitEvent.trigger(res);
		});
		webApiService.on('FincheckerWsController', 'LivenessStart', (res: WsResponseModelT<String>) => {
			self.LivenessStartEvent.trigger(res);
		});
		webApiService.on('FincheckerWsController', 'LivenessSaveResult', (res: WsResponseModelT<Boolean>) => {
			self.LivenessSaveResultEvent.trigger(res);
		});
	}
	IsLivenessInit() { 	
        this.webApiService.send('FincheckerWsController', 'IsLivenessInit' );
    }
	LivenessStart(returnUrl: string) { 	
        this.webApiService.send('FincheckerWsController', 'LivenessStart' , returnUrl);
    }
	LivenessSaveResult() { 	
        this.webApiService.send('FincheckerWsController', 'LivenessSaveResult' );
    }
	 // async methods
	async isLivenessInitAsync(): Promise<FincheckerLivenessStatusResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.IsLivenessInit.bind(this), this.IsLivenessInitEvent)();
	}
	async livenessStartAsync(returnUrl: string): Promise<String> {
		return EventToAsyncDecorator.getInstance().bind(this.LivenessStart.bind(this), this.LivenessStartEvent)(returnUrl);
	}
	async livenessSaveResultAsync(): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.LivenessSaveResult.bind(this), this.LivenessSaveResultEvent)();
	}
}
