import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import LegalBeneficiarySaveDataRequest from '../../../../../identification-services/models/legal-beneficiary/LegalBeneficiarySaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import LegalBeneficiaryGetDataResponse from '../../../../../identification-services/models/legal-beneficiary/LegalBeneficiaryGetDataResponse';

export default class LegalBeneficiaryWsController {
	webApiService: WebApiService;
	GetBeneficiaryDataEvent = new Events<WsResponseModelT<LegalBeneficiaryGetDataResponse>>();
	SaveBeneficiaryDataEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LegalBeneficiaryWsController', 'GetBeneficiaryData', (res: WsResponseModelT<LegalBeneficiaryGetDataResponse>) => {
			self.GetBeneficiaryDataEvent.trigger(res);
		});
		webApiService.on('LegalBeneficiaryWsController', 'SaveBeneficiaryData', (res: WsResponseModelT<Boolean>) => {
			self.SaveBeneficiaryDataEvent.trigger(res);
		});
	}
	GetBeneficiaryData() { 	
        this.webApiService.send('LegalBeneficiaryWsController', 'GetBeneficiaryData' );
    }
	SaveBeneficiaryData(request: LegalBeneficiarySaveDataRequest) { 	
        this.webApiService.send('LegalBeneficiaryWsController', 'SaveBeneficiaryData' , request);
    }
	 // async methods
	async getBeneficiaryDataAsync(): Promise<LegalBeneficiaryGetDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetBeneficiaryData.bind(this), this.GetBeneficiaryDataEvent)();
	}
	async saveBeneficiaryDataAsync(request: LegalBeneficiarySaveDataRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.SaveBeneficiaryData.bind(this), this.SaveBeneficiaryDataEvent)(request);
	}
}
