import { WebApiService } from '../../../../plugins/webApiService'
import { Events } from '../../../../plugins/events'
import EventToAsyncDecorator from '../../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../../schema-builder-server/models/WsResponseModelT';
import GetBusinessActivityResponse from '../../../../identification-services/models/business-activity/GetBusinessActivityResponse';
import CustomizationResponse from '../../../../aba/cabinet/web/models/CustomizationResponse';
import DictionaryItem from '../../../../dictionary-services/models/DictionaryItem';

export default class DictionaryWsController {
	webApiService: WebApiService;
	GetBusinessActivitiesListEvent = new Events<WsResponseModelT<Array<GetBusinessActivityResponse>>>();
	GetCustomizationEvent = new Events<WsResponseModelT<CustomizationResponse>>();
	GetCryptNetworkListEvent = new Events<WsResponseModelT<Array<DictionaryItem>>>();
	GetCryptCurrencyListEvent = new Events<WsResponseModelT<Array<DictionaryItem>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('DictionaryWsController', 'GetBusinessActivitiesList', (res: WsResponseModelT<Array<GetBusinessActivityResponse>>) => {
			self.GetBusinessActivitiesListEvent.trigger(res);
		});
		webApiService.on('DictionaryWsController', 'GetCustomization', (res: WsResponseModelT<CustomizationResponse>) => {
			self.GetCustomizationEvent.trigger(res);
		});
		webApiService.on('DictionaryWsController', 'GetCryptNetworkList', (res: WsResponseModelT<Array<DictionaryItem>>) => {
			self.GetCryptNetworkListEvent.trigger(res);
		});
		webApiService.on('DictionaryWsController', 'GetCryptCurrencyList', (res: WsResponseModelT<Array<DictionaryItem>>) => {
			self.GetCryptCurrencyListEvent.trigger(res);
		});
	}
	GetBusinessActivitiesList() { 	
        this.webApiService.send('DictionaryWsController', 'GetBusinessActivitiesList' );
    }
	GetCustomization() { 	
        this.webApiService.send('DictionaryWsController', 'GetCustomization' );
    }
	GetCryptNetworkList() { 	
        this.webApiService.send('DictionaryWsController', 'GetCryptNetworkList' );
    }
	GetCryptCurrencyList() { 	
        this.webApiService.send('DictionaryWsController', 'GetCryptCurrencyList' );
    }
	 // async methods
	async getBusinessActivitiesListAsync(): Promise<Array<GetBusinessActivityResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetBusinessActivitiesList.bind(this), this.GetBusinessActivitiesListEvent)();
	}
	async getCustomizationAsync(): Promise<CustomizationResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCustomization.bind(this), this.GetCustomizationEvent)();
	}
	async getCryptNetworkListAsync(): Promise<Array<DictionaryItem>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCryptNetworkList.bind(this), this.GetCryptNetworkListEvent)();
	}
	async getCryptCurrencyListAsync(): Promise<Array<DictionaryItem>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCryptCurrencyList.bind(this), this.GetCryptCurrencyListEvent)();
	}
}
