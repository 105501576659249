import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import GetWalletsListRequest from '../../../../../user-wallets/models/wallet/GetWalletsListRequest';
import EnumWalletCurrency from '../../../../../aba/data/enums/wallets/EnumWalletCurrency';
import GetHistoryRequest from '../../../../../user-wallets/models/wallet-transaction-history/GetHistoryRequest';
import WalletBalanceRequest from '../../../../../user-wallets/models/wallet-balance/WalletBalanceRequest';
import WalletPayoutRequest from '../../../../../user-wallets/models/wallet-transaction-maker/WalletPayoutRequest';
import ConvertCriptoToFiatAmountRequest from '../../../../../user-wallets/models/crypto-conversion/ConvertCriptoToFiatAmountRequest';
import ConvertAmountToCriptoRequest from '../../../../../user-wallets/models/crypto-conversion/ConvertAmountToCriptoRequest';
import WalletConvertFiatToCryptRequest from '../../../../../user-wallets/models/wallet-transaction-maker/WalletConvertFiatToCryptRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import GetWalletsListResponse from '../../../../../user-wallets/models/wallet/GetWalletsListResponse';
import WalletReplenishSettings from '../../../../../user-wallets/models/wallet-info/WalletReplenishSettings';
import GetWalletTransactionHistoryResponse from '../../../../../user-wallets/models/wallet-transaction-history/GetWalletTransactionHistoryResponse';
import CurrencyResponse from '../../../../../user-wallets/models/wallet-info/CurrencyResponse';
import WalletBalanceResponse from '../../../../../user-wallets/models/wallet-balance/WalletBalanceResponse';
import ConvertCriptoToFiatAmountResponse from '../../../../../user-wallets/models/crypto-conversion/ConvertCriptoToFiatAmountResponse';
import ConvertAmountToCriptoResponse from '../../../../../user-wallets/models/crypto-conversion/ConvertAmountToCriptoResponse';

export default class WalletWsController {
	webApiService: WebApiService;
	GetWalletsListEvent = new Events<WsResponseModelT<GetWalletsListResponse>>();
	GetWalletReplenishEvent = new Events<WsResponseModelT<WalletReplenishSettings>>();
	GetHistoryEvent = new Events<WsResponseModelT<GetWalletTransactionHistoryResponse>>();
	GetCurrencyListEvent = new Events<WsResponseModelT<Array<CurrencyResponse>>>();
	GetWalletBalanceEvent = new Events<WsResponseModelT<WalletBalanceResponse>>();
	WalletPayoutInitEvent = new Events<WsResponseModelT<Boolean>>();
	ConvertCriptoToFiatAmountEvent = new Events<WsResponseModelT<ConvertCriptoToFiatAmountResponse>>();
	ConvertAmountToCriptoEvent = new Events<WsResponseModelT<ConvertAmountToCriptoResponse>>();
	WalletConvertFiatToCryptInitEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('WalletWsController', 'GetWalletsList', (res: WsResponseModelT<GetWalletsListResponse>) => {
			self.GetWalletsListEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetWalletReplenish', (res: WsResponseModelT<WalletReplenishSettings>) => {
			self.GetWalletReplenishEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetHistory', (res: WsResponseModelT<GetWalletTransactionHistoryResponse>) => {
			self.GetHistoryEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetCurrencyList', (res: WsResponseModelT<Array<CurrencyResponse>>) => {
			self.GetCurrencyListEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetWalletBalance', (res: WsResponseModelT<WalletBalanceResponse>) => {
			self.GetWalletBalanceEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'WalletPayoutInit', (res: WsResponseModelT<Boolean>) => {
			self.WalletPayoutInitEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'ConvertCriptoToFiatAmount', (res: WsResponseModelT<ConvertCriptoToFiatAmountResponse>) => {
			self.ConvertCriptoToFiatAmountEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'ConvertAmountToCripto', (res: WsResponseModelT<ConvertAmountToCriptoResponse>) => {
			self.ConvertAmountToCriptoEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'WalletConvertFiatToCryptInit', (res: WsResponseModelT<Boolean>) => {
			self.WalletConvertFiatToCryptInitEvent.trigger(res);
		});
	}
	GetWalletsList(request: GetWalletsListRequest) { 	
        this.webApiService.send('WalletWsController', 'GetWalletsList' , request);
    }
	GetWalletReplenish(currency: EnumWalletCurrency) { 	
        this.webApiService.send('WalletWsController', 'GetWalletReplenish' , currency);
    }
	GetHistory(request: GetHistoryRequest) { 	
        this.webApiService.send('WalletWsController', 'GetHistory' , request);
    }
	GetCurrencyList() { 	
        this.webApiService.send('WalletWsController', 'GetCurrencyList' );
    }
	GetWalletBalance(request: WalletBalanceRequest) { 	
        this.webApiService.send('WalletWsController', 'GetWalletBalance' , request);
    }
	WalletPayoutInit(request: WalletPayoutRequest) { 	
        this.webApiService.send('WalletWsController', 'WalletPayoutInit' , request);
    }
	ConvertCriptoToFiatAmount(request: ConvertCriptoToFiatAmountRequest) { 	
        this.webApiService.send('WalletWsController', 'ConvertCriptoToFiatAmount' , request);
    }
	ConvertAmountToCripto(request: ConvertAmountToCriptoRequest) { 	
        this.webApiService.send('WalletWsController', 'ConvertAmountToCripto' , request);
    }
	WalletConvertFiatToCryptInit(request: WalletConvertFiatToCryptRequest) { 	
        this.webApiService.send('WalletWsController', 'WalletConvertFiatToCryptInit' , request);
    }
	 // async methods
	async getWalletsListAsync(request: GetWalletsListRequest): Promise<GetWalletsListResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetWalletsList.bind(this), this.GetWalletsListEvent)(request);
	}
	async getWalletReplenishAsync(currency: EnumWalletCurrency): Promise<WalletReplenishSettings> {
		return EventToAsyncDecorator.getInstance().bind(this.GetWalletReplenish.bind(this), this.GetWalletReplenishEvent)(currency);
	}
	async getHistoryAsync(request: GetHistoryRequest): Promise<GetWalletTransactionHistoryResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetHistory.bind(this), this.GetHistoryEvent)(request);
	}
	async getCurrencyListAsync(): Promise<Array<CurrencyResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCurrencyList.bind(this), this.GetCurrencyListEvent)();
	}
	async getWalletBalanceAsync(request: WalletBalanceRequest): Promise<WalletBalanceResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetWalletBalance.bind(this), this.GetWalletBalanceEvent)(request);
	}
	async walletPayoutInitAsync(request: WalletPayoutRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.WalletPayoutInit.bind(this), this.WalletPayoutInitEvent)(request);
	}
	async convertCriptoToFiatAmountAsync(request: ConvertCriptoToFiatAmountRequest): Promise<ConvertCriptoToFiatAmountResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.ConvertCriptoToFiatAmount.bind(this), this.ConvertCriptoToFiatAmountEvent)(request);
	}
	async convertAmountToCriptoAsync(request: ConvertAmountToCriptoRequest): Promise<ConvertAmountToCriptoResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.ConvertAmountToCripto.bind(this), this.ConvertAmountToCriptoEvent)(request);
	}
	async walletConvertFiatToCryptInitAsync(request: WalletConvertFiatToCryptRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.WalletConvertFiatToCryptInit.bind(this), this.WalletConvertFiatToCryptInitEvent)(request);
	}
}
