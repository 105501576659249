<!-- @format -->

<template>
	<router-view />
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import $ from "jquery";
import { AdapterApiService } from './pages/_adapter';

onMounted(async () => {
	// Вставка внешних скриптов
	await addExternalScript(
		"https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js"
	);
	await addExternalScript("webflow.js");
	
	const api = AdapterApiService.getInstance();
});

async function addExternalScript(src: string) {
	const script = document.createElement("script");
	script.src = src;
	script.defer = true;
	document.head.appendChild(script);
}
</script>
<style lang="scss">
@import url(@/assets/css/normalize.css);
@import url(@/assets/css/webflow.css);
@import url(@/assets/css/swipez--new.webflow.css);
</style>
<style>
body {
	-webkit-overflow-scrolling: touch;
}
</style>
<!--  Please keep this css code to improve the font quality -->
<style>
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
<style>
body {
	overscroll-behavior: none;
}
</style>
<style>
input,
textarea {
	-webkit-appearance: none;
	border-radius: 0;
}
</style>
<style>
::-moz-selection {
	/* Firefox */
	color: #000000;
	background: #fcd713;
}

::selection {
	color: #000000;
	background: #fcd713;
}
</style>
<style>
::-webkit-scrollbar {
	width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
	-webkit-border-radius: 0px;
	background: #000000;
	border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 0px;
	border-radius: 0px;
	background: #fcd713;
	-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: #000000;
}
</style>
