export default {
  Account: 'Account',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  Contact: 'Contact',
  'Log in': 'Log in',
  'UNITED BANKING': "UNITED BANKING",
  'Connect your partners into unified ecosystem for rapid and secure fund flows': 'Connect your partners into unified ecosystem <br />for rapid and secure fund flows',
  header: {
    "Sign Up":"Sign Up",
  },
  account: {
    'PERSONAL AND BUSINESS ACCOUNT': 'PERSONAL<br />AND BUSINESS<br> ACCOUNT',
    'Manage all your financial transactions, make and receive payments. All-in-one space.': 'Manage all your financial transactions,<br> make and receive payments.<br> All-in-one space.',
    'Create Account': 'Create Account',
    'Fast online application and onboarding': 'Fast online application and onboarding',
    'Execute all banking tasks at lightspeed': 'Execute all banking tasks at lightspeed',
    'SEPA & SWIFT for easy fund transfers globally': 'SEPA & SWIFT for easy fund transfers globally',
    'Crypto payments and investments': 'Crypto payments and investments',
    'Personalized support available anytime': 'Personalized support available anytime',
    'TOP-TIER SECURITY': 'TOP-TIER SECURITY',
    'Trust': 'Trust in our state-of-the-art security protocols designed to provide peace of mind, so you can focus on managing your finances with confidence.',
    'SMOOTH TRANSACTIONS': 'SMOOTH TRANSACTIONS',
    'INTEGRATED ECOSYSTEM': 'INTEGRATED ECOSYSTEM',
    'Our platform': 'Our platform connects all your partners, employees, and contractors in one unified hub. Experience seamless collaboration and streamlined communication across your entire network.',
    'Experience':'Experience seamless and efficient transactions like never before. Our platform ensures every payment and banking task is processed swiftly, securely,and without hassle.',
  },
  'EXPLORE A COMPLETE FINANCIAL PLATFORM POWERED BY BANK ACCOUNT': 'EXPLORE A COMPLETE FINANCIAL PLATFORM POWERED BY BANK ACCOUNT',
  pricing: {
    'EXPLORE OUR PRICING': 'EXPLORE OUR PRICING',
    'BUSINESS': 'BUSINESS',
    'PERSONAL': 'PERSONAL',
    'Handling of documents and opening a customer account': 'Handling of documents and opening a customer account',
    'Account monthly service fee': 'Account monthly service fee',
    'Incoming internal paymentt': 'Incoming internal payment',
    'Incoming SEPA-paymen': 'Incoming SEPA-payment',
    'Incoming Swift payment': 'Incoming Swift payment',
    'Internal outgoing payment between own accounts within Swipez': 'Internal outgoing payment between own accounts within Swipez',
    'Internal outgoing payment within Swipez': 'Internal outgoing payment within Swipez',
    'Outgoing SEPA-payment': 'Outgoing SEPA-payment',
    'Outgoing Swift paymen': 'Outgoing Swift paymen',
    'Free of change': 'Free of change',
    
  },
  questtions: {
    'FREQUENTLY ASKED QUESTIONS': 'FREQUENTLY<br>ASKED QUESTIONS',
    'What country is Swipez registered at?': 'What country is Swipez registered at?',
    'How safe is Swipez service?': 'How safe is Swipez service?',
    'What documents must be presented for opening an account?': 'What documents must be presented for opening an account?',
    'When will I get access to the online bank?': 'When will I get access to the online bank?',
    'Residents of what countries may not open an account?': 'Residents of what countries may not open an account?',
  },
  footer: {
    'NEED OUR TEAM HELP?': 'NEED OUR<br> TEAM HELP?',
    'Privacy Policy': 'Privacy Policy',
    'Terms and Conditions': 'Terms and Conditions',
    'Claims Procedure': 'Claims Procedure',
    'Cryptocurrency Exchange Notice': 'Cryptocurrency Exchange Notice',
    'desk0': 'To learn about which Revolut company serves you or if you have any questions, please contact us through our in-app chat.',
    'desk1': 'Revolut Ltd is registered in England and Wales (No. 08804411), 7 Westferry Circus, Canary Wharf, London, England, E14 4HD and is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 (Firm Reference 900562). Revolut Travel Ltd is authorised by the Financial Conduct Authority to undertake insurance distribution activities (FCA No: 780586). Our insurance products are arranged by Revolut Travel Ltd and Revolut Ltd, which is an appointed representative of Revolut Travel Ltd. Revolut’s stock trading products are provided by Revolut Trading Ltd (No. 832790), an appointed representative of Resolution Compliance Ltd, which is authorised and regulated by the Financial Conduct Authority.',
    'desk2': 'Revolut Ltd is also registered with the Financial Conduct Authority to offer cryptocurrency services under the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017. Precious metals services are provided by Revolut Ltd and are not regulated by the Financial Conduct Authority.',
  }
  
  
};
