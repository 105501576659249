<template>
<DocumentLayout>
	<h1 class="h1--hero">
		<div class="hero-h-wrapper">
			<div data-w-id="a57a7ba6-1456-df65-733e-c340ef917366" class="h--super-large">
				Crypto
			</div>
		</div>
		<div class="hero-h-wrapper">
			<div data-w-id="a57a7ba6-1456-df65-733e-c340ef917366" class="h--super-large">
				currency
			</div>
		</div>
		<div class="hero-h-wrapper">
			<div data-w-id="201b457d-0993-bca7-76e5-88cdccd4a159" class="h--super-large">
				Exchange Notice
			</div>
		</div>
	</h1>
	<p>Draph Solutions s.r.o. provides cryptocurrency exchange services that can be conducted through our trusted partners. We strive to ensure a seamless and secure experience, regardless of the method used for the exchange.</p>
	<p>Please be aware that the involvement of trusted partners in the exchange process is subject to their own terms and conditions, which may differ from those of Draph Solutions s.r.o. While we diligently select and regularly review these partners for reliability and trustworthiness, Draph Solutions s.r.o. is not responsible for any actions, errors, or omissions by these partners.</p>
	<p>By utilizing our cryptocurrency exchange services, you acknowledge and agree that:</p>
	<ol class="litle">
		<li>
			<p>You understand the inherent risks associated with cryptocurrency transactions, including but not limited to market volatility, regulatory changes, and potential technical issues.</p>
		</li>
		<li>
			<p>Draph Solutions s.r.o. and its trusted partners do not provide investment advice, and any decision to exchange or hold cryptocurrency is made at your own discretion.</p>
		</li>
		<li>
			<p>You accept that exchanges conducted through our trusted partners may be governed by their respective policies and procedures, and you agree to comply with any such terms.</p>
		</li>
		<li>
			<p>Draph Solutions s.r.o. is not liable for any losses or damages incurred as a result of using our exchange services or those of our trusted partners.</p>
		</li>
	</ol>
	<p>We recommend that you thoroughly review the terms and conditions of any service provider before engaging in cryptocurrency exchanges. For any questions or concerns, please contact our customer support team at support@swipez.eu.</p>
	<p>Draph Solutions s.r.o. reserves the right to modify this disclaimer at any time without prior notice.</p>
</DocumentLayout>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API

import { DocumentLayout } from "@/layout";
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
// import HomeAdapterService from "./home/adapter/HomeAdapterService";

// const adapter = new HomeAdapterService();

onMounted(async () => { });
const onShowAnswer = (id: string) => { };
</script>

<style lang="less" scoped>
.h1--hero {
	width: 100%;
}

.global--padding {
	p {
		color: #fff
	}

	ul {
		li::marker {
			color: #fff;
			font-weight: bold;
		}
	}

	h2,
	h3 {
		padding-top: 1em;
		padding-bottom: .5em;
		color: #fff;
		text-align: left !important;

	}

	h4 {
		color: #fff
	}

	ol {
		margin-left: 20px;

		li::marker {
			color: #fff;
			font-weight: bold;
			font-size: 3em;
			font-family: Made Outer Sans, sans-serif;
		}

		&.litle li::marker {
			font-size: 14px;

		}
	}
}
</style>
