import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import TransactionPersonRequest from '../../../../../transaction-services/models/TransactionPersonRequest';
import ModelTransferInternal from '../../../../../i-public-service/aba-finance/models/ModelTransferInternal';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import MoneyTransactionPersonResponse from '../../../../../transaction-services/models/MoneyTransactionPersonResponse';

export default class InternalBalanceWsController {
	webApiService: WebApiService;
	GetInternalTransactionsNewEvent = new Events<WsResponseModelT<MoneyTransactionPersonResponse>>();
	CheckInternalTransferEvent = new Events<WsResponseModelT<Boolean>>();
	CheckTransferSepaEvent = new Events<WsResponseModelT<Boolean>>();
	TransferSepaEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('InternalBalanceWsController', 'GetInternalTransactionsNew', (res: WsResponseModelT<MoneyTransactionPersonResponse>) => {
			self.GetInternalTransactionsNewEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'CheckInternalTransfer', (res: WsResponseModelT<Boolean>) => {
			self.CheckInternalTransferEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'CheckTransferSepa', (res: WsResponseModelT<Boolean>) => {
			self.CheckTransferSepaEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'TransferSepa', (res: WsResponseModelT<Boolean>) => {
			self.TransferSepaEvent.trigger(res);
		});
	}
	GetInternalTransactionsNew(model: TransactionPersonRequest) { 	
        this.webApiService.send('InternalBalanceWsController', 'GetInternalTransactionsNew' , model);
    }
	CheckInternalTransfer(model: ModelTransferInternal) { 	
        this.webApiService.send('InternalBalanceWsController', 'CheckInternalTransfer' , model);
    }
	CheckTransferSepa(model: ModelTransferInternal) { 	
        this.webApiService.send('InternalBalanceWsController', 'CheckTransferSepa' , model);
    }
	TransferSepa(model: ModelTransferInternal) { 	
        this.webApiService.send('InternalBalanceWsController', 'TransferSepa' , model);
    }
	 // async methods
	async getInternalTransactionsNewAsync(model: TransactionPersonRequest): Promise<MoneyTransactionPersonResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetInternalTransactionsNew.bind(this), this.GetInternalTransactionsNewEvent)(model);
	}
	async checkInternalTransferAsync(model: ModelTransferInternal): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckInternalTransfer.bind(this), this.CheckInternalTransferEvent)(model);
	}
	async checkTransferSepaAsync(model: ModelTransferInternal): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckTransferSepa.bind(this), this.CheckTransferSepaEvent)(model);
	}
	async transferSepaAsync(model: ModelTransferInternal): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.TransferSepa.bind(this), this.TransferSepaEvent)(model);
	}
}
