import { WebApiService } from '../../../../../../plugins/webApiService'
import { Events } from '../../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../../plugins/eventToAsyncDecorator'
import AcquiringInitPayoutRequest from '../../../../../../transaction-services/models/acquirings/AcquiringInitPayoutRequest';
import WsResponseModelT from '../../../../../../schema-builder-server/models/WsResponseModelT';
import InfoForAcquiringIntPayin from '../../../../../../transaction-services/models/transfer-info-service/InfoForAcquiringIntPayin';

export default class NaturalInternalBalanceWsController {
	webApiService: WebApiService;
	CheckTransferAcquiringEvent = new Events<WsResponseModelT<InfoForAcquiringIntPayin>>();
	TransferAcquiringToCardEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('NaturalInternalBalanceWsController', 'CheckTransferAcquiring', (res: WsResponseModelT<InfoForAcquiringIntPayin>) => {
			self.CheckTransferAcquiringEvent.trigger(res);
		});
		webApiService.on('NaturalInternalBalanceWsController', 'TransferAcquiringToCard', (res: WsResponseModelT<Boolean>) => {
			self.TransferAcquiringToCardEvent.trigger(res);
		});
	}
	CheckTransferAcquiring(request: AcquiringInitPayoutRequest) { 	
        this.webApiService.send('NaturalInternalBalanceWsController', 'CheckTransferAcquiring' , request);
    }
	TransferAcquiringToCard(request: AcquiringInitPayoutRequest) { 	
        this.webApiService.send('NaturalInternalBalanceWsController', 'TransferAcquiringToCard' , request);
    }
	 // async methods
	async checkTransferAcquiringAsync(request: AcquiringInitPayoutRequest): Promise<InfoForAcquiringIntPayin> {
		return EventToAsyncDecorator.getInstance().bind(this.CheckTransferAcquiring.bind(this), this.CheckTransferAcquiringEvent)(request);
	}
	async transferAcquiringToCardAsync(request: AcquiringInitPayoutRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.TransferAcquiringToCard.bind(this), this.TransferAcquiringToCardEvent)(request);
	}
}
