import apiDataSource from "@api/dataSource/api/apiDataSource";
import { ref } from "vue";

export default class AdapterApiService {
  isInited = ref(false);
  api: apiDataSource = new apiDataSource("");
  adminUrl=ref("");
  private static instance: AdapterApiService


  constructor(api: apiDataSource|null = null) {
    
    this.fetchApi()
    if (!api && !this.api) {
      this.fetchApi()
      return
    }
    this.api = api || this.api;
    AdapterApiService.instance = this
  }

  async fetchApi() {
    
    
      let apiSettings = await fetch("/appWebSettings.json").then(async (res) => {
        return await res.text().then((result) => {
          return JSON.parse(result);
        });
      });
      this.adminUrl.value = apiSettings.adminUrl;
      this.api = new apiDataSource(apiSettings.apiUrl);
      this.api.webApiService.init();
      this.isInited.value = true;
    
  };


  public static getInstance() {

    if (!AdapterApiService.instance) {
      AdapterApiService.instance = new AdapterApiService()

    }
    return AdapterApiService.instance

  }


  // public static getInstance() {
  //   if (!UserService.instance) {
  //     UserService.instance = new UserService()
  //   }
  //   return UserService.instance
  // }
}
