import { WebApiService } from '../../../../../../plugins/webApiService'
import { Events } from '../../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../../plugins/eventToAsyncDecorator'
import TransactionPersonRequest from '../../../../../../transaction-services/models/TransactionPersonRequest';
import GetTransactionsRequest from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsRequest';
import WsResponseModelT from '../../../../../../schema-builder-server/models/WsResponseModelT';
import MoneyTransactionPersonResponse from '../../../../../../transaction-services/models/MoneyTransactionPersonResponse';
import GetTransactionsResponse from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsResponse';

export default class LegalPaymentWsController {
	webApiService: WebApiService;
	GetHistoryTransactionNewEvent = new Events<WsResponseModelT<MoneyTransactionPersonResponse>>();
	GetInternalTransactionsEvent = new Events<WsResponseModelT<GetTransactionsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LegalPaymentWsController', 'GetHistoryTransactionNew', (res: WsResponseModelT<MoneyTransactionPersonResponse>) => {
			self.GetHistoryTransactionNewEvent.trigger(res);
		});
		webApiService.on('LegalPaymentWsController', 'GetInternalTransactions', (res: WsResponseModelT<GetTransactionsResponse>) => {
			self.GetInternalTransactionsEvent.trigger(res);
		});
	}
	GetHistoryTransactionNew(model: TransactionPersonRequest) { 	
        this.webApiService.send('LegalPaymentWsController', 'GetHistoryTransactionNew' , model);
    }
	GetInternalTransactions(request: GetTransactionsRequest) { 	
        this.webApiService.send('LegalPaymentWsController', 'GetInternalTransactions' , request);
    }
	 // async methods
	async getHistoryTransactionNewAsync(model: TransactionPersonRequest): Promise<MoneyTransactionPersonResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetHistoryTransactionNew.bind(this), this.GetHistoryTransactionNewEvent)(model);
	}
	async getInternalTransactionsAsync(request: GetTransactionsRequest): Promise<GetTransactionsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetInternalTransactions.bind(this), this.GetInternalTransactionsEvent)(request);
	}
}
