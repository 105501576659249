import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import EventToAsyncDecorator from '../../../../../plugins/eventToAsyncDecorator'
import LegalBusinessProfileSaveDataRequest from '../../../../../identification-services/models/legal-business-profile/LegalBusinessProfileSaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder-server/models/WsResponseModelT';
import LegalBusinessProfileGetDataResponse from '../../../../../identification-services/models/legal-business-profile/LegalBusinessProfileGetDataResponse';

export default class LegalBusinessProfileWsController {
	webApiService: WebApiService;
	GetBusinessProfileEvent = new Events<WsResponseModelT<LegalBusinessProfileGetDataResponse>>();
	SaveBusinessProfileDataEvent = new Events<WsResponseModelT<Boolean>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LegalBusinessProfileWsController', 'GetBusinessProfile', (res: WsResponseModelT<LegalBusinessProfileGetDataResponse>) => {
			self.GetBusinessProfileEvent.trigger(res);
		});
		webApiService.on('LegalBusinessProfileWsController', 'SaveBusinessProfileData', (res: WsResponseModelT<Boolean>) => {
			self.SaveBusinessProfileDataEvent.trigger(res);
		});
	}
	GetBusinessProfile() { 	
        this.webApiService.send('LegalBusinessProfileWsController', 'GetBusinessProfile' );
    }
	SaveBusinessProfileData(request: LegalBusinessProfileSaveDataRequest) { 	
        this.webApiService.send('LegalBusinessProfileWsController', 'SaveBusinessProfileData' , request);
    }
	 // async methods
	async getBusinessProfileAsync(): Promise<LegalBusinessProfileGetDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetBusinessProfile.bind(this), this.GetBusinessProfileEvent)();
	}
	async saveBusinessProfileDataAsync(request: LegalBusinessProfileSaveDataRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.SaveBusinessProfileData.bind(this), this.SaveBusinessProfileDataEvent)(request);
	}
}
